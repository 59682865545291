<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="error-template">
          <h1>Oops!</h1>
          <h2>404 Not Found</h2>
          <div class="error-details">
            Sorry, an error has occured, Requested page not found!
          </div>
          <div class="error-actions">
            <router-link
              v-if="is_authenticate"
              to="/data-structure"
              class="btn btn-primary btn-lg"
              ><span class="glyphicon glyphicon-home"></span>Take Me Home
            </router-link>
            <router-link
              v-if="!is_authenticate"
              to="/"
              class="btn btn-primary btn-lg"
              ><span class="glyphicon glyphicon-home"></span>Take Me Home
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { IS_USER_AUTHENCATED } from "@/store/storeconstants";
export default {
  computed: {
    ...mapGetters("auth", {
      is_authenticate: IS_USER_AUTHENCATED,
    }),
  },
};
</script>